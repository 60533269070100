import { FC } from "react";
import { EALIGN, ECOLOR, EVARIANTS, IButton } from "./interface";

export const BTN_VARIANTS_MAPPING = {
  [EVARIANTS.BOX]: "rounded-lg",
  [EVARIANTS.PILL]: "rounded-full",
  [EVARIANTS.LINK]: "border-none bg-transparent",
};
export const BTN_COLORS_MAPPING = {
  [ECOLOR.ONE]: "bg-[#FFFFFF] text-[#000000] rounded-[15px]",
  [ECOLOR.TWO]: "bg-[#F75D37] text-[#FFFFFF]",
  [ECOLOR.THREE]: "bg-[#FFFFFF] border border-[#000000]  text-[#787878]",
  [ECOLOR.FOUR]: "bg-[#FFFFFF] text-[#1AB65C] border border-[#1AB65C]",
  [ECOLOR.FIVE]: "bg-grey-400 text-white",
  [ECOLOR.SIX]: "bg-grey-800 text-white",
  [ECOLOR.SEVEN]: "bg-grey-100 text-grey-500",
  [ECOLOR.EIGHT]: "border border-gray-500 text-black",
  [ECOLOR.NINE]: "border border-[#28B281] text-[#28B281]",
  [ECOLOR.TEN]: "bg-transparent text-[#484848]",
};

export const ALIGN_MAPPING = {
    [EALIGN.LEFT]   : 'text-left',
    [EALIGN.CENTER] : 'text-center',
    [EALIGN.RIGHT]  : 'text-right'
};

const Button: FC<IButton> = ({ className: classNameProp, align = EALIGN.CENTER, disabled, children, onClick = () => { }, variant = EVARIANTS.BOX, color = ECOLOR.ONE, isFullWidth }) => {
    const isWidthFull = isFullWidth ? "w-full" : "w-auto";
    const buttonColor = disabled ? ECOLOR.ONE : color;
    const className = `${classNameProp} ${ALIGN_MAPPING[align]} ${BTN_VARIANTS_MAPPING[variant as keyof typeof BTN_VARIANTS_MAPPING]} ${BTN_COLORS_MAPPING[buttonColor as keyof typeof BTN_COLORS_MAPPING]} ${isWidthFull} font-["Montserrat"] py-2 px-[30px] text-sm flex gap-x-4 items-center justify-center`;
    const onButtonClick = (e:any) => {
        if (!disabled) {
            onClick(e);
        }
    };
    return (
      <button className={className} onClick={onButtonClick}>
        <span  className={`flex items-center text-[10px] md:text-[16px]}`}>
          {children}
        </span>
      </button>
    );
};


export default Button;
