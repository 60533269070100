import Navbar from "./components/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import MobileNav from "./components/MobileNav";
import { useLocation } from "react-router-dom";
import HomestaySelector from "./components/HomestaySelector";
import useCommonStore from "./store/store";
import "./App.css";


function App() {
  const location = useLocation();
  const { isLoggedIn,authData } = useCommonStore();
  return (
    <div>
      <main className="relative flex  min-h-screen flex-col">
      <div className="flex items-center mb-[-20px] justify-center">
        <HomestaySelector authData={authData}/>
      </div>
        <div className="hidden md:block">
        <Navbar isAuthenticated={isLoggedIn}/>
        </div>
        <div className="min-h-screen mb-[100px]">
          <Outlet />        
        </div>
        {location.pathname !== "/" && <MobileNav />}
        <Footer/>
      </main>
    </div>
  );
}
export default App;
