import Button from "../../Button";
import { EVARIANTS, ECOLOR } from "../../Button/interface";
import { useState, useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { API_ROUTES } from "../../../api";
import useCommonStore from "../../../store/store";
import moment from "moment";
import { getUTCDate } from "../../../utils/dateFormatter";

const BookingStats = ({ selectedDate ,activeTab,toggleTab}: any) => {
  const [bookingData, setBookingData] = useState(null);
  const {homestayData} = useCommonStore();

  const { data: bookingStats, error, apiClient: bookingStatsClient } = useApi();

  // const handleClose = () => {
  //   toggleBookingDetail(false);
  // };

  const getBookingStats = async () => {
    const url = API_ROUTES.BOOKING_STATS;
    const options = {
      params: {
        date: getUTCDate(selectedDate),
        homeStayId: homestayData?._id
      },
    };
    await bookingStatsClient(url, options);
  };

  useEffect(() => {
    getBookingStats();
  }, [selectedDate,homestayData]);

  useEffect(() => {
    if (bookingStats?.data) {
      setBookingData(bookingStats?.data);
    }
  }, [bookingStats]);

const handleClick=(e:any,value:string)=>{
    e?.preventDefault();
    toggleTab(value)
}

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="flex flex-col justify-center items-center  border-r-[2px] border-[#D9D9D9] px-3">
          <p className="font-['Montserrat'] font-semibold text-[18px] leading-[25px] tracking-[0.2px] text-[#000000] ">
            {bookingData?.checkIns || 0}
          </p>
          <p className="font-['Montserrat'] font-semibold text-[14px] leading-[19px] tracking-[0.2px] text-[#000000] ">
            Check-Ins
          </p>
        </div>

        <div className="flex flex-col justify-center items-center border-r-[2px] border-[#D9D9D9] px-3">
          <p className="font-['Montserrat'] font-semibold text-[18px] leading-[25px] tracking-[0.2px] text-[#000000] ">
          {bookingData?.checkOuts || 0}
          </p>
          <p className="font-['Montserrat'] font-semibold text-[14px] leading-[19px] tracking-[0.2px] text-[#000000] ">
            Check-Outs
          </p>
        </div>

        <div className="flex flex-col justify-center items-center px-3">
          <p className="font-['Montserrat'] font-semibold text-[18px] leading-[25px] tracking-[0.2px] text-[#000000] ">
          {bookingData?.bookings || 0}
          </p>
          <p className="font-['Montserrat'] font-semibold text-[14px] leading-[19px] tracking-[0.2px] text-[#000000] ">
            Bookings
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row mt-6 justify-center items-center gap-y-3 md:gap-x-5 mb-3">
        <div className="flex gap-x-5">
          {/* <Button variant={EVARIANTS.PILL} color={ECOLOR.FOUR} className="">
            <p className="font-Urbanist font-semibold text-[15px] leading-[21px] tracking-[0.2px] text-[#1AB65C]">
              New {`(${bookingData?.today})`}
            </p>
          </Button> */}

          <Button variant={EVARIANTS.PILL} color={ECOLOR.FOUR} onClick={(e)=>handleClick(e,'new')}  className={`${activeTab === 'new' ? '!bg-[#F75D37] !border-none' :'' }`}>
            <p className={`font-Urbanist font-semibold text-[15px] leading-[21px] tracking-[0.2px] ${activeTab === 'new' ? 'text-[#FFFFFF]' : 'text-[#1AB65C]'}`}>

              New {`(${bookingData?.today || 0})`}
            </p>
          </Button>
          <Button variant={EVARIANTS.PILL} color={ECOLOR.FOUR} onClick={(e)=>handleClick(e,'upcoming')} className={`${activeTab === 'upcoming' ? '!bg-[#F75D37] !border-none' :'' }`}>
            <p className={`font-Urbanist font-semibold text-[15px] leading-[21px] tracking-[0.2px] ${activeTab === 'upcoming' ? 'text-[#FFFFFF]' : 'text-[#1AB65C]'}`}>

              Upcoming {`(${bookingData?.upcoming || 0})`}
            </p>
          </Button>
        </div>

        <div className="flex gap-x-5">
        <Button variant={EVARIANTS.PILL} color={ECOLOR.FOUR} onClick={(e)=>handleClick(e,'past')} className={`${activeTab === 'past' ? '!bg-[#F75D37] !border-none' :'' }`}>
            <p className={`font-Urbanist font-semibold text-[15px] leading-[21px] tracking-[0.2px] ${activeTab === 'past' ? 'text-[#FFFFFF]' : 'text-[#1AB65C]'}`}>

              Past {`(${bookingData?.past || 0})`}
            </p>
          </Button>
          <Button variant={EVARIANTS.PILL} color={ECOLOR.FOUR} onClick={(e)=>handleClick(e,'cancelled')} className={`${activeTab === 'cancelled' ? '!bg-[#F75D37] !border-none' :'' }`}>
            <p className={`font-Urbanist font-semibold text-[15px] leading-[21px] tracking-[0.2px] ${activeTab === 'cancelled' ? 'text-[#FFFFFF]' : 'text-[#1AB65C]'}`}>

              Cancelled {`(${bookingData?.cancelled || 0})`}
            </p>
          </Button>
        </div>
      </div>
    </>
  );
};

export default BookingStats;
