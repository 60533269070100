import BulkIcon from "../Icon/Bulk";
import BookingIcon from "../Icon/Booking";
import ProfileIcon from "../Icon/Profile";
import RateIcon from "../Icon/Rate";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const MobileNav = () => {
  const location = useLocation();
  return (
    <div className="flex md:hidden justify-between gap-x-[40px] items-start fixed bottom-0 bg-[#FFFFFF] w-full px-3 py-2 border-t-2 text-center">
      <NavLink to="/bulk-update"  className="text-[#F75D37]">
      <div className="flex justify-center items-center flex-col" >
        <BulkIcon isActive = {location.pathname === '/bulk-update'} />
        <p className={`font-['Montserrat'] font-medium text-[10px] leading-[12px] ${location.pathname === '/bulk-update' ? 'text-[#F75D37]' : 'text-[#787878]' } text-[#787878]`}>
          Bulk Update
        </p>
      </div>
      </NavLink>

      <NavLink to="/booking" >
        <div className="flex justify-center items-center flex-col">
          <BookingIcon isActive = {location.pathname === '/booking'} />
          <p className={`font-['Montserrat'] font-medium text-[10px] leading-[12px] ${location.pathname === '/booking' ? 'text-[#F75D37]' : 'text-[#787878]' } text-[#787878]`}>
            Booking
          </p>
        </div>
      </NavLink>

      <NavLink to="/rate">
      <div className="flex justify-center items-center flex-col">
        <RateIcon isActive = {location.pathname === '/rate'} />
        <p className={`font-['Montserrat'] font-medium text-[10px] leading-[12px] ${location.pathname === '/rate' ? 'text-[#F75D37]' : 'text-[#787878]' } text-[#787878]`}>
          Inventory & Rates
        </p>
      </div>
      </NavLink>

      <NavLink to="/profile">
      <div className="flex justify-center items-center flex-col">
        <ProfileIcon isActive = {location.pathname === '/profile'} />
        <p className={`font-['Montserrat'] font-medium text-[10px] leading-[12px] ${location.pathname === '/profile' ? 'text-[#F75D37]' : 'text-[#787878]' } text-[#787878]`}>
          Profile
        </p>
      </div>
      </NavLink>
    </div>
  );
};

export default MobileNav;
