import React, { useState } from 'react';
import 'react-dates/initialize';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import checkIsMobile from '../../../utils/checkIsMobile';
import 'react-dates/lib/css/_datepicker.css';
import './style.css'

const DatePicker = ({setToAndFromDate,startDate,endDate}:any) => { 
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    setToAndFromDate(prev=>({...prev, "from":startDate}));
    setToAndFromDate(prev=>({...prev, "to":endDate}));
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);  
    if (focusedInput === "startDate") {
        setToAndFromDate(null);
        setToAndFromDate(null);
    }
  };

  const isOutsideRange = (day) => {
    return false
  };

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="start_date_id"
      endDate={endDate}
      endDateId="end_date_id"
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onFocusChange={handleFocusChange}
      startDatePlaceholderText="From"
      endDatePlaceholderText="To"
      displayFormat="DD MMM YY" 
      customInputIcon={<img src="/images/Calendar.svg" />} 
      inputIconPosition="after"
      small={true}
      numberOfMonths={checkIsMobile() ? 1 : 2}
      isOutsideRange={isOutsideRange}
      minimumNights={0}
    />
  );
};

export default DatePicker;
