import Input from "../../Input";
import { INPUT_TYPES } from "../../Input";
import Button from "../../Button";
import { ECOLOR, EVARIANTS } from "../../Button/interface";
import { useState, useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { API_ROUTES } from "../../../api";
import moment from "moment";
import { SnakeLoader } from "../../Loader";
import { isPositiveNumber } from "../../../utils";
import useCommonStore from "../../../store/store";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { getUTCDate } from "../../../utils/dateFormatter";

const AdjustRate = ({ setSelectedRoomData,loading,selectedDate,selectedDays,rooms,toggleAdjustRate,getRoomInRange,setRoomData}: any) => {
  const [loader, toggleLoader] = useState(false);
  const {homestayData} = useCommonStore();
  const [error,setError] = useState({
  adults: '',
  price: '',
  breakfast: '',
  lunch: '',
  dinner: '',
  allMeals: '',
  extraBedCharge:''});

  const { data: dayPriceUpdateResponse, error:dayPriceError, loading:dayPriceLoading, apiClient: dayPriceUpdateClient } = useApi();
  const { data: bulkPriceResponse, error:bulkPriceError,loading:bulkPriceLoading, apiClient: bulkPriceClient } = useApi();

  const handleCancel = () => {
    toggleLoader(true);
    setSelectedRoomData({});
    setRoomData(null);
    getRoomInRange();
    toggleLoader(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedRoomData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const updatePriceByDays = async () => {
    if(isValidateInput(rooms))
    {
      toggleLoader(true);
      const days = selectedDays.map((item:any) => item.value);
      const url = API_ROUTES.PRICE_UPDATE_BY_DAYS;
      const options = {
        method: "POST",
        data: {
          days: days,
          type: rooms?.roomType,
          adults: rooms?.adults,
          price: {
            room: rooms?.price,
            meals: {
              breakfast: rooms?.breakfast,
              lunch: rooms?.lunch,
              dinner: rooms?.dinner,
              allMeals: rooms?.allMeals,
            },
          },
          homeStayId: homestayData?._id
        },
      };
  
      await dayPriceUpdateClient(url, options);

    }
  
  };

  const updateBulkPrice = async () => {
    if(isValidateInput(rooms))
    { toggleLoader(true);
      const url = API_ROUTES.BULK_PRICE;
      const options = {
        method: "post",
        data: {
          from: getUTCDate(selectedDate?.from),
          to: getUTCDate(selectedDate?.to),
          type: rooms?.roomType,
          adults: rooms?.adults,
          price: {
            room: rooms?.price,
            meals: {
              breakfast: rooms?.breakfast,
              lunch: rooms?.lunch,
              dinner: rooms?.dinner,
              allMeals: rooms?.allMeals,
            },
          },
          homeStayId: homestayData?._id
        },
      };
  
      await bulkPriceClient(url, options);
    }
    
  };

 const handleSaveChange = () => {
   if (selectedDays.length > 0) {
     updatePriceByDays();
   } else {
     updateBulkPrice();
   }
 };

 useEffect(()=>{
  if(bulkPriceResponse?.status || dayPriceUpdateResponse?.status){
    setSelectedRoomData({  });
    setRoomData(null);
    getRoomInRange();
    toggleLoader(false)

  }
 },[bulkPriceResponse,dayPriceUpdateResponse])


 const isValidateInput = (value: any) => {
  setError({
    adults: "",
    price: "",
    breakfast: "",
    lunch: "",
    dinner: "",
    allMeals: "",
    extraBedCharge:""
  });
   let isValid = true;
   if (!isPositiveNumber(value?.adults)) {
     setError((prev) => ({ ...prev, adults: "Enter valid adult Value" }));
     isValid = false;
   }
   if (!isPositiveNumber(value?.price)) {
     setError((prev) => ({ ...prev, price: "Enter valid price Value" }));
     isValid = false;
   }
   if (!isPositiveNumber(value?.lunch)) {
     setError((prev) => ({ ...prev, lunch: "Enter valid lunch rate" }));
     isValid = false;
   }
   if (!isPositiveNumber(value?.dinner)) {
     setError((prev) => ({ ...prev, dinner: "Enter valid dinner rate" }));
     isValid = false;
   }
   if (!isPositiveNumber(value?.breakfast)) {
     setError((prev) => ({ ...prev, breakfast: "Enter valid breakfast rate" }));
     isValid = false;
   }
   if (!isPositiveNumber(value?.allMeals)) {
     setError((prev) => ({ ...prev, allMeals: "Enter valid all meals rate" }));
     isValid = false;
   }
  //  if (!isPositiveNumber(value?.extraBedCharge)) {
  //   setError((prev) => ({ ...prev, extraBedCharge: "Enter valid  extra bed charge" }));
  //   isValid = false;
  // }
   return isValid;
 };

  return (
    <>
    {
      loader ? <SnakeLoader/> : <>   

      <div className="flex h-full flex-col md:hidden gap-y-6 overflow-y-auto">
        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Number of Adults
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Number of adults"
            name="adults"
            value={rooms?.adults}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.adults &&  <p className="text-red-600 text-sm font-bold">{error?.adults}</p>}
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Price
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Price"
            name="price"
            value={rooms?.price}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.price &&  <p className="text-red-600 text-sm font-bold">{error?.price}</p>}

        </div>

        <p className="font-['Montserrat'] font-semibold text-[16px] leading-[22px] tracking-[0.2px] text-[#000000] ">
          Meals Price (per person)
        </p>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Breakfast Rate (optional)
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Breakfast rate"
            name="breakfast"
            value={rooms?.breakfast}          
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.breakfast &&  <p className="text-red-600 text-sm font-bold">{error?.breakfast}</p>}
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Lunch rate (optional)
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Lunch rate"
            name="lunch"
            value={rooms?.lunch}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.lunch &&  <p className="text-red-600 text-sm font-bold">{error?.lunch}</p>}
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Dinner Rate (optional){" "}
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Dinner rate"
            name="dinner"
            value={rooms?.dinner}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.dinner &&  <p className="text-red-600 text-sm font-bold">{error?.dinner}</p>}
        </div>

        <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Combined Meal Rate (optional)
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Combined meal rate"
            name="allMeals"
            value={rooms?.allMeals}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.allMeals &&  <p className="text-red-600 text-sm font-bold">{error?.allMeals}</p>}
        </div>

        {/* <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Extra Bed Charge
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Extra Bed Charge"
            name="extraBedCharge"
            value={rooms?.extraBedCharge}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.extraBedCharge &&  <p className="text-red-600 text-sm font-bold">{error?.extraBedCharge}</p>}
        </div> */}

        <div className="flex mt-3 flex-row gap-x-2 justify-between">
          <Button
            variant={EVARIANTS.PILL}
            color={ECOLOR.THREE}
            className="!rounded-[12px] flex-1 !px-[10px] "
            onClick={handleCancel}
          >
            <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#787878]">
              Cancel
            </p>
          </Button>
          <Button
            variant={EVARIANTS.PILL}
            color={ECOLOR.TWO}
            className="!rounded-[12px] flex-1"
            onClick={handleSaveChange}
          >
            <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#FFFFFF]">
              Save Changes
            </p>
          </Button>
        </div>
      </div>

      <div className="hidden h-full md:flex justify-center gap-x-[100px] items-start">
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2">
            <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
              Number of Adults
            </label>
            <Input
              type={INPUT_TYPES.TEXT}
              placeholder="Number of adults"
              name="adults"
              value={rooms?.adults}
              onChange={onInputChange}
              className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
            />
            {error?.adults &&  <p className="text-red-600 text-sm font-bold">{error?.adults}</p>}
          </div>

          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[22px] tracking-[0.2px] text-[#000000] ">
            Meals Price (per person)
          </p>

          <div className="flex flex-col gap-y-2">
            <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
              Breakfast Rate (optional)
            </label>
            <Input
              type={INPUT_TYPES.TEXT}
              placeholder="Breakfast rate"
              name="breakfast"
              value={rooms?.breakfast}
              onChange={onInputChange}
              className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
            />
            {error?.breakfast &&  <p className="text-red-600 text-sm font-bold">{error?.breakfast}</p>}
          </div>

          <div className="flex flex-col gap-y-2">
            <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
              Lunch rate (optional)
            </label>
            <Input
              type={INPUT_TYPES.TEXT}
              placeholder="Lunch rate"
              name="lunch"
              value={rooms?.lunch}
              onChange={onInputChange}
              className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
            />
            {error?.lunch &&  <p className="text-red-600 text-sm font-bold">{error?.lunch}</p>}
          </div>
        </div>

        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2">
            <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
              Price
            </label>
            <Input
              type={INPUT_TYPES.TEXT}
              placeholder="Price"
              name="price"
              value={rooms?.price}
              onChange={onInputChange}
              className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
            />
            {error?.price &&  <p className="text-red-600 text-sm font-bold">{error?.price}</p>}
          </div>

          <div className="flex flex-col mt-[48px] gap-y-2">
            <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
              Dinner Rate (optional){" "}
            </label>
            <Input
              type={INPUT_TYPES.TEXT}
              placeholder="Dinner rate"
              name="dinner"
              value={rooms?.dinner}
              onChange={onInputChange}
              className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
            />
            {error?.dinner &&  <p className="text-red-600 text-sm font-bold">{error?.dinner}</p>}
          </div>

          <div className="flex flex-col gap-y-2">
            <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
              Combined Meal Rate (optional)
            </label>
            <Input
              type={INPUT_TYPES.TEXT}
              placeholder="Combined meal rate"
              name="allMeals"
              value={rooms?.allMeals}
              onChange={onInputChange}
              className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
            />
            {error?.allMeals &&  <p className="text-red-600 text-sm font-bold">{error?.allMeals}</p>}
          </div>

          {/* <div className="flex flex-col gap-y-2">
          <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
            Extra Bed Charge
          </label>
          <Input
            type={INPUT_TYPES.TEXT}
            placeholder="Extra Bed Charge"
            name="extraBedCharge"
            value={rooms?.extraBedCharge}
            onChange={onInputChange}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          {error?.extraBedCharge &&  <p className="text-red-600 text-sm font-bold">{error?.extraBedCharge}</p>}
        </div> */}
        </div>
      </div>

      <div className="hidden md:flex mt-6 flex-row gap-x-2 items-center justify-center">
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.THREE}
          className="!rounded-[12px] !px-[60px]"
          onClick={handleCancel}
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#787878]">
            Cancel
          </p>
        </Button>
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.TWO}
          className="!rounded-[12px] "
          onClick={handleSaveChange}
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#FFFFFF]">
            Save Changes
          </p>
        </Button>
      </div>
      </>
}
    </>
  );
};

export default AdjustRate;
