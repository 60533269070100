import { ButtonHTMLAttributes } from 'react';

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: any;
    onClick?: (e: any) => void;
    variant?: string;   
    color?: string;
    isFullWidth?: boolean;    
    align?:string;
}

export enum ECOLOR {
    ONE = 'white',
    TWO = 'orange',
    THREE = 'gray-outline',
    FOUR = 'green-outline',
    FIVE = 'bg-gray3',
    SIX = 'gray4',
    SEVEN = 'bg-outline',
    EIGHT = 'bg-greenOutline',
    NINE = 'bg-TRANSPARENT',
    TEN = 'bg-gradient',
   
}

export enum EVARIANTS {
    BOX = 'Box',
    PILL = 'Pill',
    LINK = 'Link'
}

export enum EALIGN {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right'
}