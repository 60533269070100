import Login from "../Login/v2";
import useCommonStore from "../../store/store";

const Home = () => {
    const { isLoggedIn } = useCommonStore(); 
  return (
    <>
      <div className="md:max-w-[1440px]  px-0 md:px-24 md:mx-auto">
        <div className="relative h-full justify-center items-center md:mt-[20px] flex flex-col">
         { !isLoggedIn ?  <Login /> : <h1 className="mt-[100px]">Welcome to Culture Travel Host</h1>}         
        </div>
      </div>
    </>
  );
};

export default Home;
