import config from "../utils/getConfig";

export const API_ROUTES = {
  SEND_OTP: `${config.API_HOST}/api/auth/otp/send`,
  VERIFY_OTP: `${config.API_HOST}/api/auth/otp/verify`,
  LOGOUT: `${config.API_HOST}/api/auth/logout`,
  PROFILE: `${config.API_HOST}/api/auth/v1/profile`,
  BOOKING_STATS: `${config.API_HOST}/api/admin/bookings/stats`,
  BOOKINGS: `${config.API_HOST}/api/admin/v1/bookings`,
  ROOMLIST: `${config.API_HOST}/api/admin/v1/rooms`,
  BLOCK_PROPERTY: `${config.API_HOST}/api/admin/block/all`,
  PRICE_UPDATE: `${config.API_HOST}/api/admin/price`,
  UPDATE_DISCOUNT: `${config.API_HOST}/api/admin/discounts`,
  UPDATE_BANK: `${config.API_HOST}/api/admin/bank-details`,
  BANK_DETAIL: `${config.API_HOST}/api/admin/bank-details`,
  ROOM_WITHIN_RANGE: `${config.API_HOST}/api/admin/rooms/range`,
  BULK_PRICE: `${config.API_HOST}/api/admin/price/bulk`,
  BULK_DISCOUNT: `${config.API_HOST}/api/admin/discounts/range`,
  BLOCK_ROOM: `${config.API_HOST}/api/admin/block`,
  BULK_ROOM_BLOCK: `${config.API_HOST}/api/admin/block/bulk`,
  BULK_PROPERTY_BLOCK: `${config.API_HOST}/api/admin/block/all/bulk`,
  PRICE_UPDATE_BY_DAYS : `${config.API_HOST}/api/admin/price/bulk/days`
};
