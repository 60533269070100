import Input, { INPUT_TYPES } from "../../Input";
import Button from "../../Button";
import { ECOLOR, EVARIANTS } from "../../Button/interface";
import useApi from "../../../hooks/useApi";
import { API_ROUTES } from "../../../api";
import { useState, useEffect } from "react";

const BankDetail = ({ toggleBankDetail, homestayData }: any) => {
  const { data: bankResponse, error,loading, apiClient: bankClient } = useApi();
  const {
    data: bankDetail,
    error: bankError,
    apiClient: getBankClient,
  } = useApi();

  const [bankData, setBankData] = useState({
    accountHolderName: homestayData?.accountHolderName,
    accountNumber: homestayData?.accountNumber,
    ifscCode: homestayData?.ifscCode,
    bankName: homestayData?.bankName,
    upiId: homestayData?.upiId,
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBankData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const getBankDetail = async () => {
    const url = API_ROUTES.BANK_DETAIL;
    await getBankClient(url);
  };

  useEffect(() => {
    getBankDetail();
  }, []);

  useEffect(() => {
    if (bankDetail?.status) {
      setBankData({
        ...bankDetail?.data?.bankDetails,
        upiId: bankDetail?.data?.upiId,
      });
    }
  }, [bankDetail]);

  const updateBankDetail = async () => {
    const url = API_ROUTES.UPDATE_BANK;
    const options = {
      method: "POST",
      data: {
        bankDetails: {
          accountHolderName: bankData?.accountHolderName,
          accountNumber: bankData?.accountNumber,
          ifscCode: bankData?.ifscCode,
          bankName: bankData?.bankName,
        },
        upiId: bankData?.upiId,
      },
    };
    await bankClient(url, options);
  };

  const handleClick = () => {
    updateBankDetail();
  };
  useEffect(() => {
    if (bankResponse?.status) {
      toggleBankDetail(false);
      setBankData({
        accountHolderName: bankResponse?.data?.bankDetails?.accountHolderName,
        accountNumber: bankResponse?.data?.bankDetails?.accountNumber,
        ifscCode: bankResponse?.data?.bankDetails?.ifscCode,
        bankName: bankResponse?.data?.bankDetails?.bankName,
        upiId: bankResponse?.data?.upiId,
      });
    }
  }, [bankResponse]);

  return (
    <>
      <div className="flex flex-col gap-y-3 px-3 py-4">
        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Account holder name"
          name="accountHolderName"
          value={bankData?.accountHolderName}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />

        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Account Number"
          name="accountNumber"
          value={bankData?.accountNumber}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />

        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="IFSC Code"
          name="ifscCode"
          value={bankData?.ifscCode}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />

        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="Bank Name"
          name="bankName"
          value={bankData?.bankName}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />

        <Input
          type={INPUT_TYPES.TEXT}
          placeholder="UPI Id (optional)"
          name="upiId"
          value={bankData?.upiId}
          onChange={onInputChange}
          className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        />

        <div className="flex mt-3 flex-row justify-between">
          <Button
            variant={EVARIANTS.PILL}
            color={ECOLOR.THREE}
            className="!rounded-[12px] px-[30px] "
          >
            <p
              className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#787878]"
              onClick={() => toggleBankDetail(false)}
            >
              Cancel
            </p>
          </Button>
          <Button
            variant={EVARIANTS.PILL}
            color={ECOLOR.TWO}
            className="!rounded-[12px] px-[30px] "
          >
            <p
              className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#FFFFFF]"
              onClick={handleClick}
            >
              Save
            </p>
          </Button>
        </div>
      </div>
    </>
  );
};

export default BankDetail;
