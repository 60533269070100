import moment from "moment";

const Card = ({ handleBookingDetail, bookingData }: any) => {
  return (
    <>
      <div
        className="flex flex-col shadow-[0_4px_21px_3px_rgba(0,0,0,0.16)] border rounded-[11px] px-4 py-3 md:hidden"
        onClick={() => handleBookingDetail(bookingData)}
      >
        <p className="font-Urbanist font-bold text-[22px] leading-[30px] tracking-[0.2px] text-[#000000]">
          {bookingData?.guestDetails?.name}
        </p>
        <div className="flex flex-row items-center gap-x-3">
          {/* {bookingData?.reservation.map((item) => {
            return (
              <>
                <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] tracking-[0.2px] text-[#787878]">
                  {`${item?.type} - ${item?.count}`}
                </p>
              </>
            );
          })} */}
          <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] tracking-[0.2px] text-[#787878]">
            {bookingData?.guestDetails?.phone}
          </p>

          <div className="h-[29px] w-[110px] bg-[#28B28140] flex items-center justify-center  rounded-[4px]">
            <p className="font-['Montserrat'] font-semibold text-[12px] leading-[14px] text-[#0AA46D]">
              {bookingData?.isPaid && "confirmed"}
            </p>
          </div>
        </div>

        <div className="h-[1px] bg-[#D9D9D9] my-3"></div>

        <div className="flex gap-x-[70px] flex-row ">
          <div className="flex gap-y-2 flex-col">
            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Check-In
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {moment(bookingData?.checkIn).format("DD MMM YYYY")}
              </p>
            </div>

            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Net Amount
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {bookingData?.amount}{" "}
              </p>
            </div>
          </div>

          <div className="flex gap-y-2 flex-col">
            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Check-Out
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {moment(bookingData?.checkOut).format("DD MMM YYYY")}
              </p>
            </div>

            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Booking Id
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {bookingData?.bookingId}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden flex-col shadow-[0_4px_21px_3px_rgba(0,0,0,0.16)] border rounded-[16px] bg-[#FFFFFF] px-4 py-3 md:flex">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <p className="font-Urbanist font-bold text-[22px] leading-[30px] tracking-[0.2px] text-[#000000]">
              {bookingData?.guestDetails?.name}
            </p>
            <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] tracking-[0.2px] text-[#787878]">
              {bookingData?.guestDetails?.phone}
            </p>
            <div className="h-[1px] my-3 bg-[#D9D9D9] w-[303px]"></div>
          </div>

          <div className="h-[39px] w-[149px] bg-[#28B28140] flex items-center justify-center  rounded-[4px]">
            <p className="font-['Montserrat'] font-semibold text-[18px] leading-[21px] text-[#0AA46D]">
              {bookingData?.isPaid && "confirmed"}
            </p>
          </div>
        </div>

        {/* <div className="flex flex-row items-center gap-x-3">
          <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] tracking-[0.2px] text-[#787878]">
            Deluxe Room -1
          </p>
          <div className="h-[29px] w-[110px] bg-[#28B28140] flex items-center justify-center  rounded-[4px]">
            <p className="font-['Montserrat'] font-semibold text-[12px] leading-[14px] text-[#0AA46D]">
              confirmed
            </p>
          </div>
        </div> */}

        <div className="flex gap-x-[70px] flex-row justify-between">
          <div className="flex gap-y-2 flex-col">
            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Check-In
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {moment(bookingData?.checkIn).format("DD MMM YYYY")}
              </p>
            </div>

            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Reservation
              </p>
              {bookingData?.reservation.map((item) => {
                return (
                  <>
                    <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px">
                      {`${item?.type} - ${item?.count}`}
                    </p>
                  </>
                );
              })}
            </div>
          </div>

          <div className="flex gap-y-2 flex-col ">
            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Check-Out
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {moment(bookingData?.checkOut).format("DD MMM YYYY")}
              </p>
            </div>

            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                No. of Adults{" "}
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {bookingData?.guestDetails?.adults}
              </p>
            </div>
          </div>

          <div className="flex gap-y-2 flex-col">
            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Net Amount
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {bookingData?.amount}
              </p>
            </div>

            {/* {bookingData?.meals &&
              bookingData?.meals.map((meal) => {
                return (
                  <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                    Lunch Included
                  </p>
                );
              })} */}

            <div className="flex flex-col">
              {bookingData?.meals.length>0 && (
                <>
                  <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                    Meals{" "}
                  </p>
                  {bookingData?.meals.map((meal: string) => {
                    return (
                      <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                        {meal}
                      </p>
                    );
                  })}
                </>
              )}
            </div>
          </div>

          <div className="flex gap-y-2 flex-col">
            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Booking Id
              </p>
              <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                {bookingData?.bookingId}
              </p>
            </div>
          </div>

          <div className="flex self-justify gap-y-2 flex-col ">
            {bookingData?.bookingId && <img src="/images/paid.jpg"></img>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
