import Button from "../Button";
import { ECOLOR, EVARIANTS } from "../Button/interface";
import Input, { INPUT_TYPES } from "../Input";
import { STATES } from "../../utils/constant";
import Modal from "../Modal/v2";
import { useState } from "react";
import BankDetail from "./BankDetail";
import { useEffect } from "react";
import useApi from "../../hooks/useApi";
import { API_ROUTES } from "../../api";
import { Link } from "react-router-dom";
import useCommonStore from "../../store/store";

const Profile = () => {
  const [showBankDetail, toggleBankDetail] = useState(false);
  const { apiClient: logoutApiClient, ...logoutOptions } = useApi();
  const { removeAuthData,authData,homestayData:activeHomestay } = useCommonStore();
  const [homestayData,setHomestayData] = useState(null);

  useEffect(() => {
    if (activeHomestay) {
      setHomestayData({
        nameToBeDisplayed: activeHomestay?.nameToBeDisplayed,
        experience: authData?.data?.experience,
        originalName: activeHomestay?.name,
        address: activeHomestay?.address?.street,
        city: activeHomestay?.address?.city,
        pincode: activeHomestay?.address?.pincode,
        state: activeHomestay?.address?.state,
        phone: activeHomestay?.owner?.phone,
        email: activeHomestay?.owner?.email,
        accountHolderName: authData?.data?.paymentDetails?.bankDetails?.accountHolderName,
        accountNumber: authData?.data?.paymentDetails?.bankDetails?.accountNumber,
        bankName: authData?.data?.paymentDetails?.bankDetails?.bankName,
        ifscCode: authData?.data?.paymentDetails?.bankDetails?.ifscCode,
        upiId: authData?.data?.paymentDetails?.upiId,
        homeStayId: activeHomestay?.homeStayId,
      });
    }
  }, [activeHomestay]);

  const logoutHandler = async () => {
    await logoutApiClient(API_ROUTES.LOGOUT);
  };


  useEffect(() => {
    if (logoutOptions.data && logoutOptions.data.status) {
      removeAuthData();
      window.location.reload();
    }
  }, [logoutOptions.data]);

  return (
    <div className="px-3 mb-[80px] md:my-[80px] my-[30px] m w-11/12 md:max-w-[1440px] px-0 lg:px-24 mx-auto sm:my-24">
      <div className="flex justify-between  items-center">
        <p className="font-['Montserrat'] font-semibold text-[22px] leading-[30px] tracking-[0.2px]">
          Profile
        </p>
        <Button onClick={logoutHandler}>
          <div className="flex items-center  gap-x-[1px]">
            <img
              src="/images/profile/logout.png"
              className="w-[19px] h-[19px]"
            ></img>
            <p className="font-['Montserrat'] font-semibold text-[17px] leading-[23px] text-[#F75555] tracking-[0.2px]">
              Logout
            </p>
          </div>
        </Button>
      </div>

      <div className="flex  flex-col">
        <div className="flex justify-center md:self-start flex-col">
          <p className="font-['Montserrat'] text-center fond-bold text-[24px] leading-[33px] tracking-[0.2px] font-[#000000]">
            {homestayData?.nameToBeDisplayed}
          </p>

            {homestayData?.experience=== 'full' && <div className="flex items-center md:self-start justify-center">
            <img
              src="/images/profile/verified.png"
              className="h-[40px] w-[40px]"
            ></img>
            <p className="font-['Montserrat'] font-normal text-[16px] leading-[22px] tracking-[0.2px] text-[#000000]">
              Verified
            </p>
          </div>}
        </div>

        <div className="flex flex-col gap-y-3">
          <Input
            disabled
            type={INPUT_TYPES.TEXT}
            placeholder="Homestay Name"
            name="homestayName"
            value={homestayData?.nameToBeDisplayed}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />

          <Input
            disabled
            type={INPUT_TYPES.TEXT}
            placeholder="Address"
            name="address"
            value={homestayData?.address}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />

          <Input
            disabled
            type={INPUT_TYPES.TEXT}
            placeholder="City"
            name="city"
            value={homestayData?.city}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />

          <Input
            disabled
            type={INPUT_TYPES.TEXT}
            placeholder="Pincode"
            name="pincode"
            value={homestayData?.pincode}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />

          <select
             disabled
            id="state"
            name="state"
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          >
            <option value="">{homestayData?.state ? homestayData?.state : "State"}</option>
            {STATES.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>

          <Input
            disabled
            type={INPUT_TYPES.TEXT}
            placeholder="Contact"
            name="phone"
            value={homestayData?.phone}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
          <Input
            disabled
            type={INPUT_TYPES.TEXT}
            placeholder="Email Id (optional)"
            name="email"
            value={homestayData?.email}
            className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
          />
        </div>

        {/* <div className="flex flex-row items-center gap-x-1">
          <img
            src="/images/profile/location.png"
            className="w-[24px] h-[24px]"
          ></img>
          <p
            onClick={() => toggleLocationDetail(true)}
            className="font-['Montserrat'] cursor-pointer underline underline-offset-4 font-normal text-[16px] leading-[22px] tracking-[0.2px] text-[#000000]"
          >
            Confirm your location
          </p>
        </div> */}

        <div className="flex flex-row items-center gap-x-1">
          <img
            src="/images/profile/location.png"
            className="w-[24px] h-[24px]"
          ></img>
          <p
            onClick={() => toggleBankDetail(true)}
            className="font-['Montserrat'] cursor-pointer underline underline-offset-4 font-normal text-[16px] leading-[22px] tracking-[0.2px] text-[#000000]"
          >
            Enter your bank Details
          </p>
        </div>
        {/* <Button
          color={ECOLOR.TWO}
          variant={EVARIANTS.PILL}
          // onClick={() => toggleModal(true)}
          //   disabled={sendOtpLoading || verifyOtpLoading}
          isFullWidth={true}
          className="h-[57px] mt-6 !rounded-[15px]"
        >
          <p className="font-['Montserrat']  font-semibold text-[16px] leading-[19px] ">
            Update
          </p>
        </Button> */}

        <div className="flex my-5 justify-between items-center gap-x-3 flex-row">
          <div className="border rounded-[24px] border-[#F3F2F1CC] shadow-xl flex flex-col gap-y-2 flex-1 h-[177px] items-center justify-center">
            <img
              className="w-[40px] h-[40px]"
              src="/images/profile/plus.png"
            ></img>
            <p className="text-center font-inter font-semibold text-[14px] leading-[17px] tracking-[0.02px] text-[#787878]">
              Add Images/Videos
            </p>
          </div>

        <Link to={`https://culturetravel.in/home/${homestayData?.homeStayId}`} className="flex flex-1">
          <div className="border rounded-[24px] shadow-xl  border-[#F3F2F1CC] flex flex-1 h-[177px] gap-y-2 flex-col items-center justify-center">
            <img
              src="/images/profile/home.png"
              className="w-[24px] h-[24px] mt-2"
            ></img>
            <p className="text-center font-inter font-semibold text-[14px] leading-[17px] tracking-[0.02px] text-[#787878]">
              Tap here to check Homestay
            </p>
          </div>
          </Link>
        </div>

        <div className=" h-[135px] py-3 rounded-[24px] w-full border border-[#F3F2F133] shadow-xl flex flex-col relative items-center justify-between">
          <img src="/images/profile/contact.png" className="mt-[-60px]"></img>
          <p className="font-inter font-bold text-[19px] leading-[22px] text-[#787878] absolute top-[80px]">
            99904 77711
          </p>
        </div>
      </div>

      {showBankDetail && (
        <Modal
          show={showBankDetail}
          preventClose={true}
          title="Enter your bank detail"
        >
          <BankDetail homestayData={homestayData} toggleBankDetail={toggleBankDetail} />
        </Modal>
      )}
    </div>
  );
};

export default Profile;
