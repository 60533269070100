import { create } from "zustand";

interface CommonStoreState {
  isLoggedIn: boolean;
  authData: any; 
  setAuthData: (authData: any) => void;
  removeAuthData: () => void;
  homestayData:any;
  setHomestay: (homestayData: any) => void;

}

const useCommonStore = create<CommonStoreState>((set) => ({
  isLoggedIn: false,
  authData: {},
  homestayData:{},
  setAuthData: (authData) => set({ isLoggedIn: authData.isLoggedIn, authData }),
  removeAuthData: () => set({ isLoggedIn: false, authData: {} }),
  setHomestay: (homestayData) => set({ homestayData }),

}));

export default useCommonStore;
