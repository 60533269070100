import moment from "moment";
import useApi from "../../../hooks/useApi";
import { useState, useEffect } from "react";
import { API_ROUTES } from "../../../api";
import Card from "../Card";
import { SnakeLoader } from "../../Loader";
import useCommonStore from "../../../store/store";
import { getUTCDate } from "../../../utils/dateFormatter";

const NewBookings = ({ selectedDate,handleBookingDetail }: any) => {
  const [bookingData, setBookingData] = useState([]);
  const {homestayData} = useCommonStore();

  const { data: newBookingData, error,loading, apiClient: newBookingClient } = useApi();

  const getNewBooking = async () => {
    const url = API_ROUTES.BOOKINGS;
    const options = {
      params: {
        type:"today",
        date: getUTCDate(selectedDate),
        homeStayId: homestayData?._id
      },
    };
    await newBookingClient(url, options);
  };

  useEffect(() => {
    getNewBooking();
  }, [selectedDate,homestayData]);

  useEffect(() => {
    if (newBookingData?.data) {
      setBookingData(newBookingData?.data);
    }
  }, [newBookingData]);
  return (
    <div className="flex flex-col gap-y-5">
      {loading && <SnakeLoader />}
      {!loading &&
        bookingData?.length > 0 &&
        bookingData?.map((booking: any) => {
          return (
            <Card
              handleBookingDetail={handleBookingDetail}
              bookingData={booking}
            />
          );
        })}

      {!loading && bookingData?.length === 0 && (
        <div className="flex self-center justify-between items-center shadow-xl px-6 py-4">
          <p className="font-inter font-bold text-[16px] leading-[15px] tracking-[0.5px] text-[#F75D37]">
            No Booking found
          </p>
        </div>
      )}
    </div>
  );
};

export default NewBookings;
