import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const SingleDayPicker = ({ value, handleDateChange,enableOutsideRange=false }) => {
  const dateValue = value ? moment(value) : null;
  const isOutsideRange = (day) => {
    if(enableOutsideRange)
    return day.isBefore(moment().startOf('day'));
    return false
  };
  return (
    <div>
      <DayPickerSingleDateController
        date={dateValue}
        onDateChange={handleDateChange}
        focused={true}
        numberOfMonths={1}
        isOutsideRange={isOutsideRange}      
      />
    </div>
  );
};

export default SingleDayPicker;
