
import { FC } from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";

const Footer: FC = () => {
    const textItem = "text-[#9D9595] font-normal text-sm md:text-base font-['Raleway']";
    const year = new Date().getFullYear();
    return (
        <>
            <div className="w-full hidden md:flex flex-col justify-between gap-y-6 bg-black-500 items-center pt-6 md:pb-6 pb-24 mt-4">
                <div className="flex w-full flex-col items-center gap-y-4 md:gap-x-4 md:flex-row mid:w-10/12 md:justify-around md:items-start p-5">
                    <div className="flex-1">
                        <Link to="/">
                            <div className="h-16 w-24 md:h-32 md:w-36 md:w-34 relative mx-auto">
                                <Logo />
                            </div>
                        </Link>
                    </div>
                    <div className="flex flex-col w-fit h-full items-center gap-y-2 text-white flex-1">
                        <h2 className="font-black text-[#FFFFFF] text-lg md:text-xl tracking-widest font-['Raleway']">Resources</h2>
                        <Link to="/help/privacy-policy" className={`${textItem}`}>Privacy Policy</Link>
                        <Link to="/help/terms" className={`${textItem}`}>Terms & Condition</Link>
                        <Link to="/help/user-agreement" className={`${textItem}`}>User Agreement</Link>
                        <Link to="/help/cancellation-policy" className={`${textItem}`}>Cancellation Policy</Link>
                    </div>
                    <div className="flex flex-col w-fit h-full justify-between items-center gap-y-5 md:gap-y-10 text-white flex-1">
                        <div className="flex flex-col items-center gap-y-2">
                            <h2 className="font-black text-[#FFFFFF] text-lg md:text-xl tracking-widest font-['Raleway']">Get in touch</h2>
                            <Link to="/contact" className={`${textItem}`}>Question or Feedback ?</Link>
                            <Link to="#" className={`${textItem}`}>We&apos;d Love to hear from you</Link>
                            <Link to="tel:+919990477711" className={`${textItem}`}>+91-9990477711</Link>
                        </div>
                        <div className="flex justify-center gap-x-6">
                            <Link to="https://www.instagram.com/culturetravel.in/" target="_blank">
                                <div className="relative h-[20px] w-[20px] aspect-square">
                                    <img src="/images/footerIcons/instagram.png" alt="instagram Icon" className="w-full h-full"   />
                                </div>
                            </Link>
                            <Link to="https://www.linkedin.com/company/culturetravel" target="_blank">
                                <div className="relative h-[20px] w-[20px] aspect-square">
                                    <img src="/images/footerIcons/linkedin.png" alt="linkedin Icon" className="w-full h-full"   />
                                </div>
                            </Link>
                            <Link to="https://www.facebook.com/people/culturetravelin/100092559784783/" target="_blank">
                                <div className="relative h-[20px] w-[20px] aspect-square">
                                    <img src="/images/footerIcons/facebook.png" alt="facebook Icon" className="w-full h-full"   />
                                </div>
                            </Link>
                            <Link to="https://www.youtube.com/@culturetravel." target="_blank">
                                <div className="relative h-[20px] w-[20px] aspect-square">
                                    <img src="/images/footerIcons/youtube.png" alt="youtube Icon" className="w-full h-full"   />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* payment icons */}
      
                <div className="md:w-[500px] w-full h-full">
                    {/* <TrustedWidget/> */}
                </div>
                <div className="w-full flex justify-center items-center gap-x-[36px]">
                    <div className="relative h-[36px] w-[80px]">
                        <img   src="/images/footerIcons/visa.png" alt="visa" className="w-full h-full" />
                    </div>
                    <div className="relative h-[36px] w-[60px]">
                        <img   src="/images/footerIcons/mastercard.png" alt="mastercard" className="w-full h-full" />
                    </div>
                    <div className="relative h-[36px] w-[80px]">
                        <img   src="/images/footerIcons/upi.png" alt="upi" className="w-full h-full" />
                    </div>
                </div>
                <div className="w-full flex flex-col gap-y-4 justify-center items-center">
                    <p className={`${textItem} tracking-wider text-center`}>&copy;Copyright {year} Real Culture Travel (OPC) Private Limited. All Right Reserved</p>
                </div>
            </div>
        </>
    );
};

export default Footer;