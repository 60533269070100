import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useCommonStore from "../../../store/store";
import { useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { API_ROUTES } from "../../../api";
import { useNavigate } from "react-router-dom";
import Button from "../../Button";
import { ECOLOR } from "../../Button/interface";

const routes = [
  // {
  //   path: "/",
  //   label: "Home",
  // },
  {
    path: "/bulk-update",
    label: "Bulk Updates",
  },
  {
    path: "/booking",
    label: "Bookings",
  },
  {
    path: "/rate",
    label: "Inventory & Rates",
  },
  {
    path: "/profile",
    label: "Profile",
  },
];

type Prop = {
  isAuthenticated?: boolean;
  onClick?: () => void;
  smHidden?: boolean;
  lgHidden?: boolean;
};

const NavItems = ({  smHidden = false, lgHidden = false ,isAuthenticated}: Prop) => {
  const location = useLocation();
  const path = location.pathname;
  const { isLoggedIn,removeAuthData } = useCommonStore();
  const navigate = useNavigate();
  

  const { apiClient: logoutApiClient, ...logoutOptions } = useApi();

  const logoutHandler = async () => {
    await logoutApiClient(API_ROUTES.LOGOUT);
  };

  useEffect(() => {
    if (logoutOptions.data && logoutOptions.data.status) {
      removeAuthData();
      window.location.reload();
    }
  }, [logoutOptions.data]);

  return (
    <ul
      className={clsx(
        "w-full flex flex-col md:flex-row items-start md:items-center gap-y-[20px] md:gap-y-0 gap-x-0 md:gap-x-[24px]",
        `${smHidden && "hidden lg:flex"}`,
        `${lgHidden && "flex-col lg:hidden"}`
      )}
    >
      {routes.map((item) => (
        <>
          {!(item.path !== "/" && isAuthenticated === false) && (
            <Link to={item.path}>
            <li
              key={item.path}
              className={`py-[12px] lg:p-0 xl:px-5 xl:py-5 w-full lg:w-fit cursor-pointer ${
                path === item.path
                  ? "lg:p-[10px] border-b-[2px] border-[#1E1E1E] text-[#1E1E1E]"
                  : "opacity-[0.5] border-b-[1px] border-[#1E1E1E] lg:border-none"
              }`}
            >
              <p
                className={`w-full lg:w-fit text-[14px] font-['Montserrat'] font-semibold`}
              >
                {item.label}
              </p>
            </li>
            </Link>
          )}
        </>
      ))}

      {/* {(isLoggedIn || isAuthenticated) && <li
        className={`py-[12px] lg:p-0 xl:px-5 xl:py-5 w-full lg:w-fit cursor-pointer ${
          path === "/logout"
            ? "lg:p-[10px] border-b-[2px] border-[#1E1E1E] text-[#1E1E1E]"
            : "opacity-[0.5] border-b-[1px] border-[#1E1E1E] lg:border-none"
        }`}
      >
      </li>} */}
    </ul>
  );
};

export default NavItems;
