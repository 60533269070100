
import { useRef , useEffect} from "react";


const OtpInput = ({value, onChange, otpLength = 6, error}:any ) => {  
  /* eslint-disable */
  const inputRefs = Array.from({ length: otpLength }, () => useRef<HTMLInputElement>(null));
  console.log(`--${value}--`);

  useEffect(() => {
    inputRefs[0].current?.focus();
  }, []);

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '');

    if (newValue.length <= 1) {
      onChange([value.slice(0, index), newValue, value.slice(index + 1)].join(''));
      if (newValue.length === 1 && index < (otpLength -1)) {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && index > 0 && !value[index]) {
      onChange([value.slice(0, index - 1), value.slice(index)].join(''));
      inputRefs[index - 1].current?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');
    if (pastedData.length === otpLength) {
      onChange(pastedData);
    } else if (pastedData.length < otpLength) {
      const newValues = pastedData.split('');
      let currentIndex = 0;
      newValues.forEach((newValue) => {
        onChange([value.slice(0, currentIndex), newValue, value.slice(currentIndex + 1)].join(''));
        if (currentIndex < (otpLength - 1)) {
          currentIndex++;
        }
      });
    }
  };
  return (
    <>
      <div className="flex flex-col w-full max-w-80 gap-x-3">
        <div className="flex flex-row gap-x-3">
          {Array.from({ length: otpLength }, (_, index) => (
            <input
              key={index}
              type="tel"
                value={value[index] || ''}
                onChange={(event) => handleChange(index, event)}
                onKeyDown={(event) => handleKeyDown(index, event)}
                onPaste={handlePaste}
                ref={inputRefs[index]}
              className="w-[63px] h-[64px]  md:w-[47px] md:h-[51px] md:py-1 md:rounded-[8px] rounded-[15px] border md:bg-[#FFFFFF] bg-[#EDEDED] md:border-[#D2D5DA] md:border-grey-400 text-center font-bold text-base md:text-2xl"
            />
          ))}
        </div>

        {error && <p className="text-red-500 text-xs font-bold mt-1">{error}</p>}
      </div>
    </>
  );
};

export default OtpInput;
