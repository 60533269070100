const RateIcon = ({ width = "24", height = "24", color="#787878",isActive }: any) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.429 4.94443H11.0488C10.8413 4.13531 10.4747 3.37889 9.97171 2.72222H13.429C13.7147 2.72222 13.9886 2.60515 14.1906 2.39678C14.3926 2.18841 14.5061 1.90579 14.5061 1.61111C14.5061 1.31642 14.3926 1.03381 14.1906 0.825436C13.9886 0.617063 13.7147 0.5 13.429 0.5H1.58148C1.29583 0.5 1.02188 0.617063 0.81989 0.825436C0.617903 1.03381 0.504428 1.31642 0.504428 1.61111C0.504428 1.90579 0.617903 2.18841 0.81989 2.39678C1.02188 2.60515 1.29583 2.72222 1.58148 2.72222H5.35116C6.06227 2.72479 6.75817 2.93481 7.3585 3.32803C7.95884 3.72124 8.43911 4.28162 8.74387 4.94443H1.58148C1.29583 4.94443 1.02188 5.06149 0.81989 5.26987C0.617903 5.47824 0.504428 5.76086 0.504428 6.05554C0.504428 6.35022 0.617903 6.63284 0.81989 6.84121C1.02188 7.04958 1.29583 7.16665 1.58148 7.16665H9.12084C8.99172 8.08953 8.54547 8.93384 7.8634 9.54569C7.18134 10.1575 6.30887 10.4962 5.40501 10.5H1.58148C1.53134 10.4948 1.48083 10.4948 1.43069 10.5C1.35932 10.4885 1.28666 10.4885 1.21528 10.5C1.14187 10.5258 1.07282 10.5633 1.01064 10.6111L0.881396 10.6889C0.822398 10.7438 0.771588 10.8075 0.730609 10.8777C0.694536 10.927 0.662132 10.979 0.633674 11.0333C0.624087 11.0996 0.624087 11.167 0.633674 11.2333C0.556922 11.3441 0.512116 11.4751 0.504428 11.6111C0.49939 11.6628 0.49939 11.7149 0.504428 11.7666C0.493316 11.8403 0.493316 11.9152 0.504428 11.9889C0.529432 12.0646 0.565774 12.1358 0.612133 12.2C0.612133 12.2 0.612134 12.2889 0.687527 12.3333L7.14983 20.1111C7.33551 20.335 7.59982 20.4737 7.88462 20.4966C8.16942 20.5196 8.45138 20.4248 8.66848 20.2333C8.88557 20.0417 9.02002 19.7691 9.04224 19.4753C9.06445 19.1814 8.97262 18.8906 8.78695 18.6666L3.92945 12.7222H5.35116C6.83324 12.7285 8.26379 12.1615 9.36045 11.133C10.4571 10.1045 11.1402 8.68928 11.2749 7.16665H13.429C13.7147 7.16665 13.9886 7.04958 14.1906 6.84121C14.3926 6.63284 14.5061 6.35022 14.5061 6.05554C14.5061 5.76086 14.3926 5.47824 14.1906 5.26987C13.9886 5.06149 13.7147 4.94443 13.429 4.94443Z"
        fill={isActive ? '#F75D37'  : "#787878"}
      />
    </svg>
  );
};

export default RateIcon;
