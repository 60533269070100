import React from "react";
import Select from "react-select";
import { DAYS } from "../../utils/constant";

const CustomMultiSelect = ({ setDays, selectedDays }:any) => {
  const handleChange = (Days:any) => {
    setDays(Days);
  };

  return (
    <>
      <Select isMulti={true} options={DAYS} value={selectedDays} onChange={handleChange} className="md:min-w-[250px] w-full" placeholder="Select Days" />
    </>
  );
};

export default CustomMultiSelect;
