import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Home from "./components/Home";
import Booking from "./components/Booking";
import Profile from "./components/Profile";
import Rates from "./components/Rates";
import PageNotFound from "./components/PageNotFound";
import BulkUpdate from "./components/Bulk";
import ProtectedComponent from "./components/ProtectedComponent";
import { Loader } from "./components/Loader";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import { API_ROUTES } from "./api";
import useApi from "./hooks/useApi";
import useCommonStore from "./store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Main = () => {
  const { data: profileData, error, apiClient: profileApiClient, loading } = useApi();
  const { isLoggedIn, setAuthData, authData } = useCommonStore();


  const getProfile = async () => {
    const url = API_ROUTES.PROFILE;
    await profileApiClient(url);
  };


  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (profileData?.data) {
      setAuthData(profileData?.data)
    }
  }, [profileData]);

  if (!('isLoggedIn' in authData)) {
    return <div className="flex mt-[200px] justify-center items-center"><Loader/></div>;
  }


  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route index element={
          isLoggedIn ?
            <Navigate to="/booking" />
            : <Home />
        } />
        <Route
          path="booking"
          element={<ProtectedComponent element={<Booking />} />}
        />
        <Route
          path="profile"
          element
          ={<ProtectedComponent element={<Profile />} />}
        />
        <Route path="rate" element={<ProtectedComponent element={<Rates />} />} />
        <Route
          path="bulk-update"
          element={<ProtectedComponent element={<BulkUpdate />} />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    )
  );
  return <RouterProvider router={router} />

};

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

reportWebVitals();
