
import React from 'react';

import Logo from '../Logo';

export const Loader = () => (
    <div className='text-center'>
        <div className="w-32 h-20 relative mx-auto">
            <Logo />
        </div>
        <p className="text-sm">अतिथि देवो भवः</p>
        <SnakeLoader />
    </div>
);

export const SnakeLoader = () => (
    <div className="text-center">
        <div className="inline-block h-3 w-3 mx-1 animate-[spinner-grow_1s_linear_infinite] rounded-full bg-current align-[-0.125em] text-green-500 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
            role="status">
            <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
            </span>
        </div>
        <div className="inline-block h-3 w-3 mx-1 animate-[spinner-grow_1s_linear_infinite] rounded-full bg-current align-[-0.125em] text-green-500 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
            role="status">
            <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
        </div>
        <div
            className="inline-block h-3 w-3 mx-1 animate-[spinner-grow_1s_linear_infinite] rounded-full bg-current align-[-0.125em] text-green-500 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
            role="status">
            <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
        </div>
        <div
            className="inline-block h-3 w-3 mx-1 animate-[spinner-grow_1s_linear_infinite] rounded-full bg-current align-[-0.125em] text-green-500 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
            role="status">
            <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
        </div>
    </div>
)