import Input from "../../Input";
import { INPUT_TYPES } from "../../Input";
import Button from "../../Button";
import { ECOLOR, EVARIANTS } from "../../Button/interface";
import { useState,useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { API_ROUTES } from "../../../api";
import useCommonStore from "../../../store/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageDiscount = ({toggleManageDiscount,roomData,setgetRoomList}:any) => {
  const [discountSlabs, setDiscountSlabs] = useState(roomData?.discounts.length ? roomData?.discounts : [{discount:'',days:''}] );
  const { data: discountResponse, error, apiClient: updateDiscount } = useApi();
  const {homestayData} = useCommonStore();

  const handleManageDiscount = async () => {
    const url = `${API_ROUTES.UPDATE_DISCOUNT}/${roomData?.roomType}`;
    const options = {
      method: "POST",
      data: {
        discounts: discountSlabs.map(({ _id, ...rest }) => rest),
        homeStayId: homestayData?._id
      },
    };
    await updateDiscount(url, options);
  };

  useEffect(() => {
    if (discountResponse?.status) {
      toggleManageDiscount(false);
      toast.success("Discount updated Successfully!!!");
      setgetRoomList(true);
    }
  }, [discountResponse]);


  const handleAddDiscountSlab = () => {
    setDiscountSlabs([...discountSlabs, { discount: "", days: "" }]);
  };

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedSlabs = [...discountSlabs];
    updatedSlabs[index][name] = value;
    setDiscountSlabs(updatedSlabs);
  };

  const handleDeleteDiscountSlab = (index:number) => {
    const updatedSlabs = [...discountSlabs];
    updatedSlabs.splice(index, 1);
    setDiscountSlabs(updatedSlabs);
  };


  const handleCancel = ()=>{
    toggleManageDiscount(false);
  }
  return (
    <div className="flex  flex-col gap-y-6 md:px-[100px] py-2 px-3">
      {discountSlabs?.map((slab, index) => {
        return (
          <>
            <div className="flex flex-row justify-between items-center mb-[-20px]">
              <p className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#28B281]">
                Discount Slab {index + 1}
              </p>
              {index !==0 && <Button onClick={() => handleDeleteDiscountSlab(index)}>
                <img
                  src="/images/rate/delete.png"
                  className="w-[19px] h-[23px]"
                ></img>
              </Button>}
            </div>

            <div className="flex flex-col md:flex-row md:jusfity-between md:gap-x-[40px]">
            <div className="flex flex-col gap-y-2">
              <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
                Discount%
              </label>
              <Input
                type={INPUT_TYPES.TEXT}
                placeholder="Discount"
                name="discount"
                value={slab.discount}
                onChange={(e) => handleChange(index, e)}
                className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
                Enter Minimum Number of days
              </label>
              <Input
                type={INPUT_TYPES.TEXT}
                placeholder="Minimum  days"
                name="days"
                value={slab.days}
                onChange={(e) => handleChange(index, e)}
                className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
              />
            </div>

            </div>
            <div className="h-[4px] bg-[#D9D9D980]"></div>

            {/* <div className="flex flex-col gap-y-2">
              <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
                Discount%
              </label>
              <Input
                type={INPUT_TYPES.TEXT}
                placeholder="Discount"
                name="discount"
                value={slab.discount}
                onChange={(e) => handleChange(index, e)}
                className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <label className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#222B45]">
                Enter Minimum Number of days
              </label>
              <Input
                type={INPUT_TYPES.TEXT}
                placeholder="Minimum  days"
                name="days"
                value={slab.days}
                onChange={(e) => handleChange(index, e)}
                className="h-[53px] px-3 w-full border border-[#C4C4C4] !rounded-[10px] bg-[#F2F2F2] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
              />
            </div> */}
          </>
        );
      })}

      <Button
        variant={EVARIANTS.LINK}
        className="self-start"
        onClick={handleAddDiscountSlab}
      >
        <p className="font-['Montserrat'] font-medium text-[20px] leading-[16px] text-[#F75D37]">
          Add Discount Slab
        </p>
      </Button>

      <div className="flex mt-3 flex-row gap-x-2 justify-between">
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.THREE}
          className="!rounded-[12px] flex-1 !px-[10px] "
          onClick={handleCancel}
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#787878]">
            Cancel
          </p>
        </Button>
        <Button
          variant={EVARIANTS.PILL}
          color={ECOLOR.TWO}
          onClick={handleManageDiscount}
          className="!rounded-[12px] flex-1"
        >
          <p className="font-['Montserrat'] font-semibold text-[16px] leading-[19px] text-[#FFFFFF]">
            Save Changes
          </p>
        </Button>
      </div>

      <ToastContainer/>
    
    </div>
  );
};

export default ManageDiscount;
