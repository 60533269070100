
type Environment = 'development' | 'production' | 'test' | 'staging';

function getConfig() {
    switch(process.env.NODE_ENV as Environment) {
        case 'production':
            return {
                API_HOST: 'https://culturetravel.in'
            }
        case 'staging' :
            return {
                API_HOST: 'https://staging.culturetravel.in'
            }
        case  'development':
            return {
                API_HOST: 'http://localhost:3002'
            }
    }
}

const config = getConfig();
export default config;