import Button from "../../Button";
import { EVARIANTS, ECOLOR } from "../../Button/interface";

const BlockConfirmation = ({isChecked,toggleBlockConfirmation,handleSubmit}:any) => {
    const unblockMessage = `This action marks your property is available on the platform and You will be able to receive more bookings.`
    const blockMessage = `This action marks your property has been sold out on the platform and You will not be able to receive more bookings.`
  return (
    <div className="flex flex-col gap-y-4 items-center justify-center">
      <div className="flex flex-row">
        <p>{!isChecked ? blockMessage : unblockMessage}</p>
      </div>

      <div className="flex justify-between items-center gap-x-5 flex-row">
        <Button variant={EVARIANTS.PILL} color={ECOLOR.THREE} onClick={()=>toggleBlockConfirmation(false)}>
          Cancel
        </Button>
        <Button variant={EVARIANTS.PILL} color={ECOLOR.TWO} onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default BlockConfirmation;
