"use client";
import Input, { INPUT_TYPES } from "../../Input";
import Button  from "../../Button/V2";

const EditOccupancy = () => {
  return (
    <div className="flex  px-4 py-3 items-center justify-center">
        <div className="md:w-[320px] flex items-center flex-col gap-y-3 my-3">
        <Input type={INPUT_TYPES.TEXT}  placeholder="Max Occupancy" />
        <Button>Save</Button>
        </div>
    </div>
  );
};

export default EditOccupancy;
