const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  showCloseIcon,
  contentClass = "",
  containerClass = "",
}: any) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center   z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>

      <div
        className={`bg-white w-11/12 md:max-w-md mx-auto   md:min-w-[500px] md:max-w-full md:h-full rounded-[32px] shadow-lg z-50 ${containerClass}`}
      >
        <div
          className={`py-[50px] text-left px-6 shadow  mb-[100px] ${contentClass}`}
        >
          <div className="flex justify-between items-center pb-3">
            <p className="font-['Montserrat'] font-bold text-[22px] leading-[33px] tracking-[0.2px] mb-3">
              {title}
            </p>
            {showCloseIcon && (
              <button onClick={onClose}>
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.0625 34.9375C11.8125 34.9375 5.125 28.25 5.125 20C5.125 11.75 11.8125 5.0625 20.0625 5.0625C28.3125 5.0625 35 11.75 35 20C35 28.1875 28.3125 34.9375 20.0625 34.9375ZM20.0625 6.625C12.75 6.625 6.74998 12.625 6.74998 19.9375C6.74998 27.25 12.75 33.25 20.0625 33.25C27.375 33.25 33.375 27.25 33.375 19.9375C33.375 12.625 27.375 6.625 20.0625 6.625Z"
                    fill="#787878"
                  />
                  <path
                    d="M13.5625 27.625L12.375 26.4375L26.5 12.3125L27.6875 13.5L13.5625 27.625Z"
                    fill="#787878"
                  />
                  <path
                    d="M26.5 27.625L12.375 13.5L13.5625 12.3125L27.6875 26.4375L26.5 27.625Z"
                    fill="#787878"
                  />
                </svg>
              </button>
            )}
          </div>

          <div className="overflow-y-auto">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
